import { BackgroundPlace, FileResponsePrivate, TestType } from '@tests/types';
import { Checkbox, Form } from 'antd';
import { always, cond, equals, map, pipe, T } from 'ramda';
import React from 'react';

import { DropType } from '@/constants';
import { DropZone } from '@/containers/DropZone';
import { useGetBackgroundsQuery } from '@/services';

import { FormBlock } from '../Wrappers/FormBlock';
import styles from './styles.module.scss';

const getBackgroundPaceTitle = cond<[BackgroundPlace], Nullable<string>>([
  [equals(BackgroundPlace.FirstPage), always('Стартовая страница')],
  [equals(BackgroundPlace.IntermediateResult), always('Промежуточный результат')],
  [equals(BackgroundPlace.Question), always('Вопросы')],
  [equals(BackgroundPlace.Result), always('Результат')],
  [T, always(null)],
]);

const getBackgroundPlaceOption = (place: BackgroundPlace) => ({
  label: getBackgroundPaceTitle(place),
  value: place,
});

const getBackgroundPlaceList = cond<[TestType], BackgroundPlace[]>([
  [equals(TestType.Quiz), always(Object.values(BackgroundPlace))],
  [
    equals(TestType.Test),
    always([BackgroundPlace.FirstPage, BackgroundPlace.Question, BackgroundPlace.Result]),
  ],
  [equals(TestType.Poll), always([BackgroundPlace.FirstPage, BackgroundPlace.Question])],
  [T, always([])],
]);

type Props = {
  backgroundImage: any;
  backgroundImageId: number;
  backgroundPlace: BackgroundPlace[];
  crosswordBackgroundImage: any;
  crosswordBackgroundImageId: number;
  id: number;
  testType: TestType;
  updateBlock: any;
};

export const TestCoverBlock: React.FC<Props> = ({
  backgroundImage,
  backgroundImageId,
  backgroundPlace,
  crosswordBackgroundImage,
  crosswordBackgroundImageId,
  id,
  testType,
  updateBlock,
}) => {
  const [form] = Form.useForm();

  const { setFieldValue, submit } = form;

  const { data = {} } = useGetBackgroundsQuery({}, { refetchOnMountOrArgChange: true });

  const handleBackgroundClick = (background: FileResponsePrivate) => {
    setFieldValue('backgroundImageId', background.id);
    submit();
  };

  if (testType === TestType.Crossword) {
    return (
      <>
        <FormBlock
          testId={id}
          blockTitle="Обложка"
          form={form}
          updateBlock={updateBlock}
          initialValues={{ backgroundImageId, backgroundPlace }}
        >
          <Form.Item
            name="backgroundImageId"
            getValueFromEvent={(value) => value?.id ?? null}
            getValueProps={() => ({
              value: backgroundImage,
            })}
          >
            <DropZone type={DropType.All} previewText="Добавьте обложку" />
          </Form.Item>
        </FormBlock>
        <FormBlock
          testId={id}
          blockTitle="Фон игрового поля"
          form={form}
          updateBlock={updateBlock}
          initialValues={{ crosswordBackgroundImage, crosswordBackgroundImageId }}
        >
          <Form.Item
            name="crosswordBackgroundImageId"
            getValueFromEvent={(value) => value?.id ?? null}
            getValueProps={() => ({
              value: crosswordBackgroundImage,
            })}
          >
            <DropZone type={DropType.Image} previewText="Добавьте фон игрового поля" />
          </Form.Item>
        </FormBlock>
      </>
    );
  }

  return (
    <FormBlock
      testId={id}
      blockTitle="Фоновая картинка"
      form={form}
      updateBlock={updateBlock}
      initialValues={{ backgroundImageId, backgroundPlace }}
    >
      {/* <div className={styles.backgroundsWrapper}> */}
      {/*   <Row gutter={[18, 18]} className={styles.backgroundsRow}> */}
      {/*     {backgrounds?.map((background) => ( */}
      {/*       <Col> */}
      {/*         <div */}
      {/*           className={styles.backgroundPreviewWrapper} */}
      {/*           onClick={() => handleBackgroundClick(background)} */}
      {/*         > */}
      {/*           <img alt="" src={background?.url} /> */}
      {/*         </div> */}
      {/*       </Col> */}
      {/*     ))} */}
      {/*   </Row> */}
      {/* </div> */}
      <Form.Item
        name="backgroundImageId"
        getValueFromEvent={(value) => value?.id ?? null}
        getValueProps={() => ({
          value: backgroundImage,
        })}
      >
        <DropZone type={DropType.All} previewText="Добавьте фоновую картинку" />
      </Form.Item>

      <Form.Item shouldUpdate name="backgroundPlace" valuePropName="checked" style={{ margin: 0 }}>
        <Checkbox.Group
          defaultValue={backgroundPlace}
          rootClassName={styles.checkboxGroup}
          options={pipe(getBackgroundPlaceList, map(getBackgroundPlaceOption))(testType)}
        />
      </Form.Item>
    </FormBlock>
  );
};
