import { AnswerResponsePrivate, AnswerType, TestType, TestValidationError } from '@tests/types';
import { Col, Form, Input, InputNumber, Radio, Row, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import { inc } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ArrowDown, Delete } from '@/assets';
import { Badge } from '@/components';
import { DropType } from '@/constants';
import { AnswerResultBlock } from '@/containers/Blocks/AnswersResultsBlock/AnswerResultBlock';
import { BlockWrapper } from '@/containers/Blocks/Wrappers';
import { DropZone } from '@/containers/DropZone';
import { useDebounce, useGetValidationError } from '@/hooks';
import { useDeleteAnswerMutation, useUpdateAnswerMutation } from '@/services';
import { isImageAnswer, isQuiz, isTest } from '@/utils';

import styles from './styles.module.scss';

const { TextArea } = Input;

type Props = {
  answer: AnswerResponsePrivate;
  answerType: AnswerType;
  canDelete: boolean;
  currentCorrectAnswer: AnswerResponsePrivate | null;
  index: number;
  maxLength?: number;
  questionAnswersError: TestValidationError;
  testType: TestType;
};

export const AnswerBlock: React.FC<Props> = ({
  answer,
  answerType,
  canDelete,
  currentCorrectAnswer,
  index,
  maxLength = 100,
  questionAnswersError,
  testType,
}) => {
  const [form] = Form.useForm();

  const { getFieldsValue, resetFields, submit } = form;

  const { id, isCorrect, media, mediaCaption, resultTitle, text } = answer;

  const initialValues = useMemo(
    () => ({
      ...answer,
    }),
    [answer],
  );

  const [count, setCount] = useState(text?.length || 0);
  const [captionCount, setCaptionCount] = useState(mediaCaption?.length || 0);
  const [showAnswerResult, setShowAnswerResult] = useState(false);

  useEffect(() => {
    resetFields();
  }, [initialValues.isCorrect, resetFields]);

  const isImageTypeAnswer = useMemo(() => isImageAnswer(answerType), [answerType]);

  const [updateBlock] = useUpdateAnswerMutation();
  const [deleteBlock] = useDeleteAnswerMutation();

  const answerError = useGetValidationError({ field: 'text', targetId: id });
  const pointsError = useGetValidationError({ field: 'points', targetId: id });
  const mediaError = useGetValidationError({ field: 'mediaId', targetId: id });

  const handleDeleteClick = useCallback(() => {
    deleteBlock(id);
  }, [deleteBlock, id]);

  const handleFormChangeHandler = useDebounce(() => {
    submit();
  }, 300);

  const handleFinish = useCallback(async () => {
    const formValues = getFieldsValue();
    await updateBlock({
      ...formValues,
      id,
    });

    if (
      currentCorrectAnswer?.id &&
      isQuiz(testType) &&
      formValues.isCorrect &&
      formValues.isCorrect !== initialValues.isCorrect
    ) {
      await updateBlock({
        id: currentCorrectAnswer?.id,
        isCorrect: false,
      });
    }
  }, [
    getFieldsValue,
    updateBlock,
    id,
    currentCorrectAnswer?.id,
    testType,
    initialValues.isCorrect,
  ]);

  const handleValuesChangeHandler = useCallback(() => {
    handleFormChangeHandler.current();
  }, [handleFormChangeHandler]);

  const onValuesChange = useCallback((event: any) => {
    setCount(event.currentTarget.value.length);
  }, []);

  const onCaptionValuesChange = useCallback((event: any) => {
    setCaptionCount(event.currentTarget.value.length);
  }, []);

  const blockHeaderFieldsAndButtons = useCallback(
    () => (
      <>
        {isTest(testType) && (
          <Row gutter={[10, 10]} align="middle">
            <Col>
              <span className={styles.pointsTitle}>Баллы за ответ</span>
            </Col>
            <Col>
              <Form.Item
                name="points"
                style={{ margin: 0 }}
                validateStatus={pointsError && 'error'}
                help={pointsError?.message}
                initialValue={0}
              >
                <InputNumber className={styles.numberInput} />
              </Form.Item>
            </Col>
          </Row>
        )}
        {isQuiz(testType) && (
          <Row gutter={[10, 10]} align="middle">
            <Col>
              <Tooltip title={questionAnswersError?.message}>
                <Row
                  align="middle"
                  className={classNames(styles.quizRadio, questionAnswersError && styles.error)}
                >
                  <Form.Item
                    shouldUpdate
                    name="isCorrect"
                    valuePropName="checked"
                    style={{ margin: 0 }}
                    className={classNames(styles.pointsTitle, isCorrect && styles.correct)}
                  >
                    <Radio>Правильный</Radio>
                  </Form.Item>
                </Row>
              </Tooltip>
              <Form.Item hidden name="points">
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col
            onClick={handleDeleteClick}
            className={classNames(styles.delete, !canDelete && styles.disabled)}
          >
            <Delete />
          </Col>
        </Row>
      </>
    ),
    [testType, pointsError, isCorrect, handleDeleteClick, canDelete, questionAnswersError],
  );

  return (
    <div className={styles.wrapper}>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleFinish}
        onValuesChange={handleValuesChangeHandler}
      >
        <BlockWrapper
          count={count}
          blockTitle={`Ответ №${inc(index)}`}
          headerChildren={blockHeaderFieldsAndButtons()}
          classname={isQuiz(testType) ? 'quizAnswer' : showAnswerResult && 'light'}
        >
          <Row gutter={[15, 15]}>
            <Col span={24}>
              <Form.Item
                name="text"
                validateStatus={answerError && 'error'}
                help={answerError?.message}
              >
                <TextArea
                  className={styles.input}
                  autoSize
                  rows={1}
                  bordered={false}
                  placeholder="Текст ответа"
                  onChange={onValuesChange}
                  maxLength={maxLength}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Col>
                  <Typography.Text className={isQuiz(testType) && styles.quizCaption}>
                    {count}/{maxLength}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            {isImageTypeAnswer && (
              <Col span={24}>
                <Row>
                  <Col
                    span={24}
                    className={classNames(styles.delimiter, showAnswerResult && styles.light)}
                  />
                </Row>
              </Col>
            )}
            <Col span={24}>
              {isImageTypeAnswer && (
                <Row className={styles.imageAnswerRow} align="middle">
                  <Col span={6}>
                    <Form.Item
                      name="mediaId"
                      getValueFromEvent={(value) => value?.id ?? null}
                      getValueProps={() => ({
                        value: media,
                      })}
                      style={{ margin: 0 }}
                    >
                      <DropZone
                        validErrorMessage={mediaError?.message}
                        type={DropType.All}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="auto">
                    <Row
                      className={classNames(
                        styles.mediaCaptionWrapper,
                        showAnswerResult && styles.light,
                      )}
                    >
                      <Col span={24}>
                        <Form.Item name="mediaCaption" style={{ margin: 0 }}>
                          <TextArea
                            className={styles.input}
                            autoSize={{ minRows: 3 }}
                            bordered={false}
                            placeholder="Подпись к изображению"
                            onChange={onCaptionValuesChange}
                            maxLength={maxLength}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Row justify="end">
                          <Col>
                            <Typography.Text className={isQuiz(testType) && styles.quizCaption}>
                              {captionCount}/100
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          {isQuiz(testType) && (
            <Row>
              <Col span={24}>
                <Row
                  align="middle"
                  justify="space-between"
                  className={classNames(styles.showResultHeader, showAnswerResult && styles.light)}
                >
                  <Col
                    onClick={() => setShowAnswerResult(!showAnswerResult)}
                    className={styles.showResultButton}
                  >
                    <Row align="middle" gutter={[8, 8]}>
                      <Col>Результат</Col>
                      <ArrowDown
                        className={classNames(showAnswerResult ? styles.up : styles.down)}
                      />
                    </Row>
                  </Col>
                  <Col>
                    {resultTitle ? (
                      <Badge className="info">Заполнен</Badge>
                    ) : (
                      <Badge className="error">Добавить</Badge>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </BlockWrapper>
      </Form>
      {showAnswerResult && (
        <Row justify="center" className={styles.resultContainer}>
          <Col span={24} sm={12}>
            <AnswerResultBlock answer={answer} key={`index-${answer.id}`} />
          </Col>
        </Row>
      )}
    </div>
  );
};
