import { MAX_LEN_ANSWER_CROSSWORD_TEXT, MAX_LEN_ANSWER_TEXT } from '../db/answer/consts';
import { TestType } from '../db/test/TestType';

export function getAnswerTextLengthByTestType(type: TestType): number {
  if (type === TestType.Crossword) {
    return MAX_LEN_ANSWER_CROSSWORD_TEXT;
  } else {
    return MAX_LEN_ANSWER_TEXT;
  }
}
