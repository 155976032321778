import {
  getResultMediaCaptionLengthByTestType,
  ResultResponsePrivate,
  TestType,
} from '@tests/types';
import { Col, Row } from 'antd';
import { dec } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DropType } from '@/constants';
// eslint-disable-next-line import/no-cycle
import { MediaBlock, RangeBlock, SubtitleBlock, TitleBlock } from '@/containers/Blocks';
import { resultsEntitySelector } from '@/selectors';
import { useUpdateResultMutation } from '@/services';
import { isCrossword } from '@/utils';

type Props = {
  index: number;
  result: ResultResponsePrivate;
  testType: TestType;
};

export const Result: React.FC<Props> = ({ index, result, testType }) => {
  const { id, media, mediaCaption, mediaId, pointsMax, pointsMin, subtitle, title } = result;

  const mediaCaptionMaxLength = getResultMediaCaptionLengthByTestType(testType);

  const [previousResult, setPreviousResult] = useState(null);

  const [updateResultBlock] = useUpdateResultMutation();

  const results = useSelector(resultsEntitySelector.selectAll);

  useEffect(() => {
    if (index > 0) {
      setPreviousResult(results[dec(index)]);
    }
  }, [index, results]);

  return (
    <Row gutter={[0, 20]}>
      {!isCrossword(testType) && (
        <Col span={24}>
          <Row justify="space-between">
            <Col span={24}>
              <RangeBlock
                id={id}
                updateResultBlock={updateResultBlock}
                pointsMax={pointsMax}
                pointsMin={pointsMin}
                resultIndex={index}
                previousResultMax={previousResult?.pointsMax}
                testType={testType}
              />
            </Col>
          </Row>
        </Col>
      )}
      <Col span={24}>
        <TitleBlock id={id} text={title} updateBlock={updateResultBlock} />
      </Col>
      <Col span={24}>
        <SubtitleBlock
          id={id}
          text={subtitle}
          captionMaxLength={350}
          updateBlock={updateResultBlock}
        />
      </Col>
      <Col span={24}>
        <MediaBlock
          id={id}
          media={media}
          mediaId={mediaId}
          updateBlock={updateResultBlock}
          mediaCaption={mediaCaption}
          mediaCaptionMaxLength={mediaCaptionMaxLength}
          blockTitle="Медиа к результату"
          mediaType={DropType.All}
        />
      </Col>
    </Row>
  );
};
