import { GetManyTestsQueryPrivate, TestType } from '@tests/types';
import { Spin, Typography } from 'antd';
import { equals, inc, isEmpty } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { InfiniteScroll } from '@/components';
import { testsEntitySelector } from '@/selectors';
import { useGetTestsListQuery } from '@/services';
import { isDefined } from '@/utils';

import { Card } from '../Card';
import styles from './styles.module.scss';

type Props = {
  filtersParams?: Partial<GetManyTestsQueryPrivate<string>>;
  order?: string;
  searchTerm?: string;
  type?: TestType | undefined;
};

export const TestsList: React.FC<Props> = ({ filtersParams, order, searchTerm, type }) => {
  const [page, setPage] = useState(0);
  const searchTermMemo = useMemo(() => searchTerm || '', [searchTerm]);
  const [filterParamsState, setFiltersParamsState] = useState(filtersParams);

  useEffect(() => {
    setPage(0);
  }, [searchTermMemo]);

  useEffect(() => {
    if (!equals(filterParamsState, filtersParams)) {
      setPage(0);
      setFiltersParamsState(filtersParams);
    }
  }, [filtersParams]);

  const getListQueryParams = useMemo(() => {
    let params = {
      offset: page * 30,
      order,
      relations: ['projects'],
    } as Partial<GetManyTestsQueryPrivate>;
    if (type) {
      params.type = type;
    }
    if (searchTerm) {
      params.title = searchTerm;
    }
    if (!isEmpty(filterParamsState)) {
      params = {
        ...params,
        ...filterParamsState,
      };
    }

    return params;
  }, [type, order, searchTerm, page, filterParamsState]);

  const fetchData = async () => {
    setPage(inc);
  };

  const { data, isFetching } = useGetTestsListQuery(getListQueryParams, {
    refetchOnMountOrArgChange: true,
  });

  const tests = useSelector(testsEntitySelector.selectAll) || [];

  if (page === 0 && isFetching) {
    return (
      <Spin
        size="large"
        style={{
          padding: '10px',
          width: '100%',
        }}
      />
    );
  }

  if (tests?.length === 0) {
    return (
      <Typography.Text className={styles.stub}>
        По вашему запросу ничего не найдено.
      </Typography.Text>
    );
  }

  return (
    <InfiniteScroll
      hasMore={isDefined(data) && data?.count > tests?.length}
      isFetching={isFetching}
      fetchData={fetchData}
    >
      <div className={styles.grid}>
        {tests?.map((test) => (
          <Card test={test} key={test.id} />
        ))}
      </div>
    </InfiniteScroll>
  );
};
