import { AnswerResponsePrivate } from '@tests/types';
import React from 'react';

import { DropType } from '@/constants';
import { MediaBlock, SubtitleBlock, TitleBlock } from '@/containers';
import { useUpdateAnswerMutation } from '@/services';

import styles from './styles.module.scss';

type Props = {
  answer: AnswerResponsePrivate;
};

export const AnswerResultBlock: React.FC<Props> = ({ answer }) => {
  const { id, resultMedia, resultMediaCaption, resultMediaId, resultSubtitle, resultTitle } =
    answer;

  const [updateBlock] = useUpdateAnswerMutation();

  return (
    <div className={styles.container}>
      <TitleBlock
        text={resultTitle}
        id={id}
        updateBlock={updateBlock}
        formItemName="resultTitle"
        inverted
      />
      <SubtitleBlock
        text={resultSubtitle}
        id={id}
        updateBlock={updateBlock}
        captionMaxLength={270}
        formItemName="resultSubtitle"
        inverted
      />
      <MediaBlock
        id={id}
        updateBlock={updateBlock}
        mediaCaption={resultMediaCaption}
        blockTitle="Медиа к результату"
        mediaId={resultMediaId}
        media={resultMedia}
        formItemNamePrefix="result"
        mediaCaptionMaxLength={100}
        inverted
        mediaType={DropType.All}
      />
    </div>
  );
};
