import { CloseOutlined } from '@ant-design/icons';
import {
  EmployeeResponsePrivate,
  GetManyEmployeesQueryPrivate,
  RoleResponsePrivate,
} from '@tests/types';
import { Avatar, Button, Col, Input, Modal, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import { inc } from 'ramda';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Search as SearchIcon } from '@/assets';
import { Badge, InfiniteScroll } from '@/components';
import { employeesEntitySelector } from '@/selectors';
import { useGetEmployeesListQuery } from '@/services';
import { getProject, getTextColor, isDefined } from '@/utils';

import styles from './styles.module.scss';

const { Search } = Input;

type UserRowProps = {
  user: EmployeeResponsePrivate<string>;
};

type RoleEditorProps = {
  employeesRefetch: () => void;
  onClose: () => void;
  role: RoleResponsePrivate<string>;
};

const UserFieldsRow = () => (
  <Row align="middle" justify="space-between" className={styles.userFieldsRow}>
    <Col span={18}>
      <Typography.Text type="secondary">ФИО</Typography.Text>
    </Col>
    <Col span={6}>
      <Typography.Text type="secondary">Проекты</Typography.Text>
    </Col>
  </Row>
);

const UserRow: React.FC<UserRowProps> = ({ user }) => (
  <Row align="middle" justify="space-between" className={styles.userRow}>
    <Col span={18}>
      <Row gutter={[8, 8]}>
        <Col>
          <Avatar size={42} src={user?.avatarId} />
        </Col>
        <Col>
          <Typography.Text strong>{user?.name}</Typography.Text>
          <br />
          <Typography.Text type="secondary" className={styles[getTextColor(user?.role.name)]}>
            {user?.role.name}
          </Typography.Text>
        </Col>
      </Row>
    </Col>
    <Col span={6}>
      <Space size={10} wrap>
        {user.projects.map((project) => (
          <Badge className="employeeProject">{getProject(project.alias)}</Badge>
        ))}
      </Space>
    </Col>
  </Row>
);

export const RoleEditor: React.FC<RoleEditorProps> = ({ employeesRefetch, onClose, role }) => {
  const [employeePage, setEmployeePage] = useState(0);

  const getEmployeeListQueryParams = useMemo(() => {
    const params = {
      offset: employeePage * 30,
      order: '+name',
      relations: ['role', 'avatar'],
      roleId: role?.id,
    } as Partial<GetManyEmployeesQueryPrivate>;

    return params;
  }, [employeePage, role]);

  const { data: employeesData, isFetching: isEmployeesFetching } = useGetEmployeesListQuery(
    getEmployeeListQueryParams,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const handleCloseRoleEditor = () => {
    employeesRefetch();
    onClose();
  };

  const employees = useSelector(employeesEntitySelector.selectAll) || [];

  const fetchEmployeesData = async () => {
    setEmployeePage(inc);
  };

  return (
    <Modal
      open
      onCancel={handleCloseRoleEditor}
      getContainer={() => document.getElementById('modal-container')}
      mask={false}
      closable={false}
      footer={null}
      centered
      rootClassName={styles.editorWrapper}
      width="80%"
    >
      <Col className={styles.editor}>
        <Row justify="space-between" className={styles.editorTitle}>
          <Typography.Text className={styles.editorTitleText}>
            Редактирование списка
          </Typography.Text>
          <CloseOutlined onClick={handleCloseRoleEditor} />
        </Row>
        <Row className={styles.searchWrapper}>
          <Search
            prefix={<SearchIcon width={24} height={24} className={styles.prefix} />}
            placeholder="Поиск по списку пользователей"
          />
        </Row>
        <InfiniteScroll
          hasMore={isDefined(employeesData) && employeesData?.count > employees?.length}
          isFetching={isEmployeesFetching}
          fetchData={fetchEmployeesData}
        >
          <UserFieldsRow />
          <Col>
            {employees?.map((employee) => (
              <UserRow user={employee} />
            ))}
          </Col>
        </InfiniteScroll>
        <Row gutter={[10, 0]} className={styles.editBtnWrapper} justify="end">
          <Col>
            <Button className={classNames(styles.editBtn, styles.editCloseBtn)} onClick={onClose}>
              Отменить
            </Button>
          </Col>
          <Col>
            <Button
              className={classNames(styles.editBtn, styles.editSaveBtn)}
              onClick={onClose}
              type="primary"
            >
              Сохранить
            </Button>
          </Col>
        </Row>
      </Col>
    </Modal>
  );
};
