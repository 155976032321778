import { Player } from '@lottiefiles/react-lottie-player';
import { TestType } from '@tests/types';
import React from 'react';

import { Loader } from '@/assets';
import { DropType } from '@/constants';
import { MediaBlock, QuestionBlock, SliderBlock } from '@/containers/Blocks';
import { AnswersBlock } from '@/containers/Blocks/AnswersBlock';
import { useGetQuestionByIdQuery, useUpdateQuestionMutation } from '@/services';

import styles from './styles.module.scss';

type Props = {
  questionId: number;
  testType: TestType;
};

export const Question: React.FC<Props> = ({ questionId, testType }) => {
  const { data: question, isLoading } = useGetQuestionByIdQuery(
    { id: questionId, relations: ['media'] },
    { refetchOnMountOrArgChange: true, skip: !questionId },
  );

  const [updateBlock] = useUpdateQuestionMutation();

  if (!question) {
    if (isLoading) {
      return <Player autoplay loop src={Loader} className={styles.loader} />;
    }

    return <div>Нет вопроса!</div>;
  }

  const { id, media, mediaCaption, mediaId, text, timeout } = question;

  return (
    <div className={styles.container}>
      <SliderBlock
        id={id}
        updateBlock={updateBlock}
        value={timeout}
        formItemName="timeout"
        blockTitle="Таймер на вопросе"
        min={0}
        max={60}
      />
      <QuestionBlock id={id} text={text} updateBlock={updateBlock} />
      <MediaBlock
        id={id}
        updateBlock={updateBlock}
        mediaCaption={mediaCaption}
        blockTitle="Медиа к вопросу"
        mediaId={mediaId}
        media={media}
        mediaType={DropType.All}
        mediaCaptionMaxLength={100}
      />
      <AnswersBlock question={question} updateBlock={updateBlock} testType={testType} />
      {/* {isQuiz(testType) && <AnswersResultsBlock question={question} />} */}
    </div>
  );
};
