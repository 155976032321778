import { TestResponsePrivate } from '@tests/types';
import { Button, Card as CardItem, Col, Divider, message, Row, Space } from 'antd';
import { always, cond, equals, T } from 'ramda';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  AchbdLogo,
  Attachments,
  FivePublicLogo,
  LifeLogo,
  Link as LinkIcon,
  MashLogo,
  Stats,
  SuperLogo,
  ZhiznLogo,
} from '@/assets';
import { Badge, DateChanged, Status } from '@/components';
import { RoutesPath } from '@/constants';

import styles from './styles.module.scss';

interface Props {
  test: TestResponsePrivate;
}

const getTypeTitle = cond<[string], Nullable<string>>([
  [equals('test'), always('Тест')],
  [equals('quiz'), always('Квиз')],
  [equals('poll'), always('Голосование')],
  [equals('crossword'), always('Кроссворд')],
  [T, always(null)],
]);

const getProject = cond<[string], Nullable<JSX.Element>>([
  [equals('mash'), always(<MashLogo />)],
  [equals('super'), always(<SuperLogo />)],
  [equals('life'), always(<LifeLogo />)],
  [equals('zhizn'), always(<ZhiznLogo />)],
  [equals('achbd'), always(<AchbdLogo />)],
  [equals('fivePublic'), always(<FivePublicLogo />)],
  [T, always(null)],
]);

export const Card: React.FC<Props> = (props) => {
  const {
    test: { title, type, questionsCount, createdAt, updatedAt, projects = [], status, id },
  } = props;

  const navigate = useNavigate();

  const handleEditTestClick = (newTab = false) => {
    if (newTab) {
      window.open(`/test/${id}`, '_blank');
    } else {
      navigate(`/test/${id}`);
    }
  };

  const handleStatsTestClick = (newTab = false) => {
    if (newTab) {
      window.open(`/test/stats/${id}`, '_blank');
    } else {
      navigate(`/test/stats/${id}`);
    }
  };

  const handleCopyTestLinkClick = () => {
    navigator.clipboard
      .writeText(
        generatePath(`${window.location.origin}${RoutesPath.TestEdit}`, {
          idOrAlias: id.toString(10),
        }),
      )
      .then(() => message.success('Ссылка на интерактив скопирована'));
  };

  return (
    <CardItem bordered={false} className={styles.card} bodyStyle={{ padding: 0 }}>
      <Row gutter={[15, 15]} style={{ height: '100%' }} align="stretch">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Row gutter={[11, 11]}>
                <Badge className={type}>{getTypeTitle(type)}</Badge>
                <Col>
                  <Status status={status} />
                </Col>
              </Row>
            </Col>
            <Col>
              <Space size={[5, 5]}>
                <Attachments /> {questionsCount}
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[10, 10]} style={{ height: '100%' }} align="stretch">
            <Col span={24}>
              <span className={styles.cardTitle}>{title}</span>
            </Col>
            <Col span={24}>
              <Row gutter={[5, 5]}>
                <Col span={24} className={styles.dates}>
                  <DateChanged date={createdAt} type="created" />
                </Col>
                <Col span={24} className={styles.dates}>
                  <DateChanged date={updatedAt} type="updated" />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider
                type="horizontal"
                style={{ borderColor: '#292B30', borderWidth: 1, margin: 0 }}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[10, 10]} style={{ height: '100%' }} align="stretch">
                <Col span={24}>
                  <Space size={10} wrap>
                    {projects.map((project) => (
                      <Badge className="project">{getProject(project.alias)}</Badge>
                    ))}
                  </Space>
                </Col>
                <Col span={24}>
                  <Row gutter={[5, 5]}>
                    <Col flex="auto">
                      <Button
                        className={styles.button}
                        // eslint-disable-next-line consistent-return
                        onMouseDown={(e) => {
                          if (e.button === 1) {
                            e.preventDefault();
                            e.stopPropagation();

                            return false;
                          }
                        }}
                        onMouseUp={(e) => {
                          handleEditTestClick(e?.nativeEvent?.which === 2);
                        }}
                      >
                        Редактировать
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className={styles.button}
                        // eslint-disable-next-line consistent-return
                        onMouseDown={(e) => {
                          if (e.button === 1) {
                            e.preventDefault();
                            e.stopPropagation();

                            return false;
                          }
                        }}
                        onMouseUp={(e) => {
                          handleStatsTestClick(e?.nativeEvent?.which === 2);
                        }}
                      >
                        <Stats />
                      </Button>
                    </Col>
                    <Col>
                      <Button className={styles.button} onClick={handleCopyTestLinkClick}>
                        <LinkIcon />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardItem>
  );
};
