import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  CreateUploadBodyPrivate,
  CreateUploadResponsePrivate,
  FileResponsePrivate,
  GetManyFilesQueryPrivate,
  GetManyFilesResponsePrivate,
} from '@tests/types';

import { ApiPath } from '@/constants';
import { authGuardQuery } from '@/services/api/queries';
import { createUrl } from '@/utils';

export interface UploadProps {
  onUploadProgress?(progressEvent: ProgressEvent): void;
  url: string;
}
export interface UploadFileProps extends UploadProps {
  contentType: string;
  data: ArrayBuffer;
}

export const filesApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    createUploadFile: builder.mutation<CreateUploadResponsePrivate, CreateUploadBodyPrivate>({
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Files, ApiPath.CreateUpload),
      }),
    }),
    deleteFile: builder.mutation<any, number>({
      query: (id) => ({
        data: {},
        method: 'DELETE',
        url: createUrl(ApiPath.Files, id),
      }),
    }),
    getBackgrounds: builder.query<GetManyFilesResponsePrivate, Partial<GetManyFilesQueryPrivate>>({
      query: () => ({
        method: 'GET',
        url: createUrl(ApiPath.Backgrounds),
      }),
    }),
    getFileById: builder.mutation<FileResponsePrivate, number>({
      query: (id) => ({
        method: 'GET',
        url: createUrl(ApiPath.Files, id),
      }),
    }),
    uploadFile: builder.mutation<unknown, UploadFileProps>({
      query: ({ contentType, data, onUploadProgress, url }) => ({
        contentType,
        data,
        headers: { 'x-amz-acl': 'public-read' },
        method: 'PUT',
        onUploadProgress,
        url,
        withAuth: false,
        withCredentials: false,
      }),
    }),
  }),
  reducerPath: 'filesApi',
});

export const {
  useCreateUploadFileMutation,
  useDeleteFileMutation,
  useGetBackgroundsQuery,
  useGetFileByIdMutation,
  useUploadFileMutation,
} = filesApi;
