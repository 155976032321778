import { Player } from '@lottiefiles/react-lottie-player';
import { TestStatus } from '@tests/types';
import { FloatButton } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ArrowTop, Loader } from '@/assets';
import { Separator, StatsProjectsFilter } from '@/components';
import { TestsList, TestStatsContainer, TestStatsPageHeader } from '@/containers';
import { useGetStatsQuery, useGetTestByIdQuery } from '@/services';

import styles from './styles.module.scss';

export const TestStatsPage: React.FC = () => {
  const { idOrAlias = '' } = useParams();

  const [currentProject, setCurrentProject] = useState<number>(null);

  const { data: test, error: errorTest } = useGetTestByIdQuery(
    {
      id: idOrAlias,
      relations: [
        'projects',
        'questions',
        'results',
        'backgroundImage',
        'crosswordBackgroundImage',
      ],
    },
    { refetchOnMountOrArgChange: true, skip: !idOrAlias },
  );

  const { data: statData, error: errorData } = useGetStatsQuery(
    {
      id: idOrAlias,
      projects: [currentProject],
    },
    { refetchOnMountOrArgChange: true, skip: !currentProject },
  );

  if (!test || errorTest) {
    return (
      <div className={styles.loaderWrapper}>
        <Player autoplay loop src={Loader} className={styles.loader} />
      </div>
    );
  }

  return (
    <>
      <div className={styles.testHeader}>
        <TestStatsPageHeader test={test} />
      </div>
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.projectTop}>
            <span className={styles.projectTopTitle}>Проект</span>
            <StatsProjectsFilter
              value={currentProject}
              allowedIds={test.projects.map((item) => item.id)}
              onChange={(value) => setCurrentProject(value)}
            />
          </div>
        </div>
        {!(!statData || errorData) && <TestStatsContainer statData={statData} test={test} />}
        <Separator />

        <TestsList
          type={null}
          filtersParams={{
            projects: [currentProject],
            status: TestStatus.Published,
          }}
          order="-publishedAt"
        />
      </div>

      <FloatButton.BackTop className={styles.scrollButton} icon={<ArrowTop />} />
    </>
  );
};
