export const RoutesPath = {
  Employees: '/employees',
  Login: '/login',
  OAuthCallback: '/:provider/auth/callback',
  Projects: '/projects',
  TechWorks: '/techworks',
  TestEdit: '/test/:idOrAlias',
  TestStatsPage: '/test/stats/:idOrAlias',
  Tests: '/tests',
};
