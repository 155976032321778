import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, Select, Spin, Tag } from 'antd';
import classNames from 'classnames';
import { inc } from 'ramda';
import { ComponentProps, Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';

import { ArrowDown, CloseTag } from '@/assets';
import { projectsEntitySelector } from '@/selectors';
import { useGetProjectsListQuery } from '@/services';
import { handlePopupScroll } from '@/utils';

import styles from './styles.module.scss';

type FiltersItem = {
  label: string;
  value: number;
};

type TagItemProps = Parameters<NonNullable<ComponentProps<typeof Select>['tagRender']>>[0];

const tagRender = (
  itemProps: TagItemProps,
  checkedList: FiltersItem[],
  setCheckedList: Dispatch<SetStateAction<FiltersItem[]>>,
) => {
  const { label, value } = itemProps;
  const onPreventMouseDown = (event: MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDeleteOption = () => {
    setCheckedList(checkedList.filter((item) => item.value !== value));
  };

  return (
    <Tag
      color="rgba(50, 99, 233, 0.15)"
      onMouseDown={onPreventMouseDown}
      onClose={handleDeleteOption}
      closable
      closeIcon={<CloseTag />}
      className={styles.tag}
    >
      {label}
    </Tag>
  );
};

const renderDropdown = (
  list: FiltersItem[],
  checkedList: FiltersItem[],
  setCheckedList: Dispatch<SetStateAction<FiltersItem[]>>,
) => {
  const handleOptionChange = (option: FiltersItem) => {
    const { label, value } = option;
    const index = checkedList.findIndex((item) => item.value === value);
    if (index === -1) {
      setCheckedList([...checkedList, option]);
    } else {
      setCheckedList(checkedList.filter(({ label: checkedName }) => checkedName !== label));
    }
  };

  const getItemByValue = (value: number) => checkedList?.some((item) => item.value === value);

  return (
    <div className={styles.selectorOptions}>
      {list.map((item) => (
        <div
          key={item.label}
          className={styles.selectorOption}
          onClick={(e) => {
            e.preventDefault();
            handleOptionChange(item);
          }}
        >
          <Checkbox
            className={classNames(
              styles.selectorCheckbox,
              getItemByValue(item.value) && styles.checkedSelectorCheckbox,
            )}
            checked={getItemByValue(item.value)}
            onChange={() => handleOptionChange(item)}
          >
            {item.label}
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

type FiltersProps = {
  checkedProjects: FiltersItem[];
  classname?: string;
  isEditor?: boolean;
  setCheckedProjects: Dispatch<SetStateAction<FiltersItem[]>>;
};

export const ProjectsFilter: React.FC<FiltersProps> = ({
  checkedProjects,
  classname,
  isEditor,
  setCheckedProjects,
}) => {
  const [page, setPage] = useState(0);

  const { data: projectsData, isFetching } = useGetProjectsListQuery(
    {
      offset: page * 30,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const projects =
    useSelector(projectsEntitySelector.selectAll)?.map((item) => ({
      label: item.name,
      value: item.id,
    })) || [];
  const hasMore = projectsData && projectsData?.count > projects?.length;

  const fetchData = async () => {
    setPage(inc);
  };

  return (
    <Select
      size="large"
      mode="multiple"
      allowClear={!isEditor}
      onClear={() => setCheckedProjects([])}
      notFoundContent={isFetching ? <Spin size="small" /> : null}
      options={projects}
      dropdownStyle={{ backgroundColor: 'var(--color-grey-lvl1)' }}
      dropdownRender={() => renderDropdown(projects, checkedProjects, setCheckedProjects)}
      value={checkedProjects}
      tagRender={(customTagsProps) =>
        tagRender(customTagsProps, checkedProjects, setCheckedProjects)
      }
      suffixIcon={<ArrowDown />}
      clearIcon={<CloseOutlined />}
      className={classNames(styles.selector, styles[classname])}
      onPopupScroll={(e) => handlePopupScroll(e, fetchData, hasMore)}
      placeholder="Выбрать"
    />
  );
};
