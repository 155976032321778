import {
  AnswerResponsePrivate,
  AnswerType,
  getAnswerTextLengthByTestType,
  QuestionResponsePrivate,
  TestType,
} from '@tests/types';
import { Col, Form, Radio, Row } from 'antd';
import classNames from 'classnames';
import { inc, last, pipe, prop } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';

import { AddAnswerButton } from '@/components';
import { AnswerBlock } from '@/containers/Blocks/AnswersBlock/AnswerBlock';
import { useDebounce, useGetValidationError } from '@/hooks';
import { useGetAnswersListQuery } from '@/services';
import { isCrossword, isImageAnswer, isQuiz, isTextAnswer } from '@/utils';

import styles from './styles.module.scss';

type Props = {
  question: QuestionResponsePrivate;
  testType: TestType;
  updateBlock: any;
};

export const AnswersBlock: React.FC<Props> = ({ question, testType, updateBlock }) => {
  const { answerType, id } = question;

  const answerMaxLength = getAnswerTextLengthByTestType(testType) || 100;

  const { data: { results: answers = [] } = {} } = useGetAnswersListQuery(
    { question: id, relations: ['media', 'resultMedia'] },
    { refetchOnMountOrArgChange: true, skip: !id },
  );

  const correctAnswer = answers.find((answer) => answer.isCorrect);

  const [currentCorrectAnswer, setCurrentCorrectAnswer] = useState<AnswerResponsePrivate | null>(
    correctAnswer || null,
  );

  const [form] = Form.useForm();

  const { getFieldsValue, submit } = form;

  useEffect(() => {
    setCurrentCorrectAnswer(correctAnswer);
  }, [correctAnswer]);

  const handleFormChangeHandler = useDebounce(() => {
    submit();
  }, 1000);

  const handleFinish = useCallback(async () => {
    const formValues = getFieldsValue();
    await updateBlock({
      ...formValues,
      id,
    });
  }, [getFieldsValue, updateBlock, id]);

  const handleValuesChangeHandler = useCallback(() => {
    handleFormChangeHandler.current();
  }, [handleFormChangeHandler]);

  const getLastAnswerSortOrder = pipe(last, prop('sortOrder'), inc);
  const questionAnswersError = useGetValidationError({ field: 'answers', targetId: id });

  return (
    <div className={styles.wrapper}>
      <Form
        form={form}
        initialValues={{ answerType }}
        onFinish={handleFinish}
        onValuesChange={handleValuesChangeHandler}
      >
        <Form.Item name="answerType">
          <Radio.Group className={styles.typeContainer}>
            <Radio.Button
              className={classNames(
                styles.answerType,
                isTextAnswer(answerType) && styles[testType],
              )}
              value={AnswerType.Text}
            >
              Текст
            </Radio.Button>
            {!isCrossword(testType) && (
              <Radio.Button
                className={classNames(
                  styles.answerType,
                  isImageAnswer(answerType) && styles[testType],
                )}
                value={AnswerType.Image}
              >
                Текст + Изображение
              </Radio.Button>
            )}
          </Radio.Group>
        </Form.Item>
      </Form>
      <Row gutter={[15, 15]}>
        {answers?.map((answer, index) => (
          <Col span={24} sm={isQuiz(testType) ? 24 : 12}>
            <AnswerBlock
              key={answer.id}
              canDelete={answers.length > 2}
              answerType={answerType}
              testType={testType}
              answer={answer}
              currentCorrectAnswer={currentCorrectAnswer}
              questionAnswersError={questionAnswersError}
              index={index}
              maxLength={answerMaxLength}
            />
          </Col>
        ))}
        {answers?.length < 10 && !isCrossword(testType) && (
          <Col span={24} sm={isQuiz(testType) ? 24 : 12}>
            <AddAnswerButton questionId={id} sortOrder={getLastAnswerSortOrder(answers)} />
          </Col>
        )}
      </Row>
    </div>
  );
};
