import { LinkOutlined, RetweetOutlined } from '@ant-design/icons';
import { Player } from '@lottiefiles/react-lottie-player';
import {
  CrosswordGrid,
  CrosswordProvider,
  CrosswordProviderImperative,
} from '@slikslaks/react-crossword';
import { TestResponsePrivate, ThemeResponsePrivate } from '@tests/types';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Loader } from '@/assets';
import {
  useGetTestEmbedPreviewQuery,
  useGetTestEmbedQuery,
  useGetThemesListQuery,
  useUpdateCrosswordLayoutMutation,
} from '@/services';
import { getIframe, isCrossword, loadScriptsFromHtml } from '@/utils';

import { FormBlock } from '../Wrappers/FormBlock';
import styles from './styles.module.scss';

const { TextArea } = Input;

type Props = {
  id: number;
  test: TestResponsePrivate;
};

export const IframeCodeBlock: FC<Props> = ({ id, test }) => {
  const [form] = Form.useForm();
  const [copySuccess, setCopySuccess] = useState(false);
  const [themeId, setThemeId] = useState<number>(1);
  const [preview, setPreview] = useState(null);

  const crosswordProvider = useRef<CrosswordProviderImperative>(null);

  const { crossword, type } = test;

  const [layoutPreview, setLayoutPreview] = useState(crossword);

  const { setFieldValue } = form;

  const { data: themesData } = useGetThemesListQuery(
    { relations: ['components'] },
    { refetchOnMountOrArgChange: true },
  );

  const getThemeById = useCallback(
    () => themesData?.results.find((theme) => theme.id === themeId),
    [themeId, themesData?.results],
  );

  const getCrosswordTheme = useCallback(
    () => getThemeById()?.components?.find((component) => component.name === 'crossword')?.options,
    [getThemeById],
  );

  const { data } = useGetTestEmbedQuery(
    { id, sendLocation: '0', themeId },
    { refetchOnMountOrArgChange: true, skip: !id },
  );

  const { data: previewData } = useGetTestEmbedPreviewQuery(
    { id, sendLocation: '0', themeId },
    { refetchOnMountOrArgChange: true, skip: !id },
  );

  const [updateLayout] = useUpdateCrosswordLayoutMutation();

  const handleCopyButtonClick = useCallback(() => {
    if (data) {
      navigator.clipboard.writeText(data.code).then(() => {
        setCopySuccess(true);
      });
    }
  }, [data]);

  const handleUpdateCrosswordLayoutMutation = async () => {
    await updateLayout({ id });
  };

  useEffect(() => {
    if (copySuccess) {
      const showSuccessTimeout = setTimeout(() => {
        setCopySuccess(false);
      }, 2000);

      return () => clearTimeout(showSuccessTimeout);
    }

    return () => {};
  }, [copySuccess]);

  useEffect(() => {
    if (data?.code && previewData?.code) {
      loadScriptsFromHtml(previewData.code);
      setPreview(getIframe(previewData.code));
      setFieldValue('iframe', data.code);
    }
  }, [data, data?.code, setFieldValue, crossword, previewData?.code, themeId]);

  useEffect(() => {
    setLayoutPreview(crossword);
  }, [crossword]);

  useEffect(() => {
    if (crosswordProvider?.current) {
      crosswordProvider.current.reset();
      crosswordProvider.current.fillAllAnswers();
    }
  }, [crossword, crosswordProvider?.current, layoutPreview]);

  if (!data || !previewData) {
    return (
      <div className={styles.loaderWrapper}>
        <Player autoplay loop src={Loader} className={styles.loader} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Select
        className={styles.themeType}
        popupClassName={styles.themeTypePopup}
        bordered={false}
        defaultValue={1}
        onChange={(target: number) => {
          setThemeId(target);
        }}
      >
        {themesData?.results?.map((theme: ThemeResponsePrivate<string>) => (
          <Select.Option
            className={styles.themeTypeOption}
            key={`theme-${theme.title}-${theme.id}`}
            value={theme.id}
          >
            <Row align="middle" gutter={[10, 10]}>
              <Col>
                <div
                  className={styles.themeColor}
                  style={{
                    background: `linear-gradient(90deg, ${theme.primaryColor} 50%, ${theme.secondaryColor} 50%)`,
                  }}
                />
              </Col>
              <Col>{theme.title}</Col>
            </Row>
          </Select.Option>
        ))}
      </Select>

      {isCrossword(type) && (
        <>
          <div className={styles.info}>Пример сетки кроссворда</div>
          <CrosswordProvider
            ref={crosswordProvider}
            data={layoutPreview}
            theme={getCrosswordTheme()}
          >
            <div className={styles.crosswordLayoutWrapper}>
              <CrosswordGrid />
            </div>
          </CrosswordProvider>
          <Button
            className={classNames(styles.defaultButton)}
            onClick={handleUpdateCrosswordLayoutMutation}
          >
            <RetweetOutlined />
            Сгенерировать новый кроссворд
          </Button>
        </>
      )}
      <FormBlock
        blockTitle="Код iframe"
        form={form}
        initialValues={{ iframe: data?.code }}
        updateBlock={null}
      >
        <Form.Item name="iframe">
          <TextArea
            className={styles.input}
            autoSize
            rows={1}
            bordered={false}
            readOnly
            placeholder="Код Iframe"
          />
        </Form.Item>
        <Button
          className={classNames(styles.defaultButton, copySuccess && styles.disabled)}
          onClick={handleCopyButtonClick}
        >
          <LinkOutlined />
          {copySuccess ? 'Код скопирован' : 'Скопировать код'}
        </Button>
      </FormBlock>
      <div className={styles.info}>
        Код можно скопировать для последующего размещения на сайтах в качестве виджета
      </div>
      <div className={styles.preview} dangerouslySetInnerHTML={{ __html: preview }} />
    </div>
  );
};
