import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Col, Row } from 'antd';

import { TechWorkAnimation } from '@/assets';
import { TestsListHeader } from '@/containers';

import styles from './styles.module.scss';

export const TechWorkPage: React.FC = () => (
  <>
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <TestsListHeader />
      </Col>
    </Row>
    <Row className={styles.wrapper}>
      <Player autoplay loop src={TechWorkAnimation} style={{ height: '308px', width: '360px' }} />
      <Row className={styles.techText}>
        <Col span={24}>
          <span>Данный раздел находится в разработке.</span>
        </Col>
        <Col span={24}>
          <span>Пожалуйста, вернитесь позже.</span>
        </Col>
      </Row>
      <Button className={styles.button} type="primary" href="/tests">
        Ко всем интерактивам
      </Button>
    </Row>
  </>
);
