import 'antd/dist/reset.css';
import '@/styles/global.scss';

import { App as AntdApp, ConfigProvider as AntdConfigProvider, theme } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import { setDefaultOptions } from 'date-fns';
import { ru } from 'date-fns/locale';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { LoadingWrapper, NotFound } from '@/components';
import { RoutesPath } from '@/constants';
import { AuthGuard } from '@/containers';
import { ConfigProvider } from '@/providers';
import { routes } from '@/routes';
import { useGetConfigQuery } from '@/services';

setDefaultOptions({ locale: ru });

export const App = () => {
  const { data: config, isLoading } = useGetConfigQuery();

  return (
    <AntdConfigProvider
      locale={ruRU}
      theme={{
        algorithm: theme.darkAlgorithm,
        components: {
          Slider: {
            dotSize: 14,
            handleActiveColor: '#253560',
            handleColor: '#253560',
            handleLineWidth: 3,
            railBg: '#3263E9A3',
            railHoverBg: '#3263E9A3',
            railSize: 8,
            trackBg: '#3263E9',
            trackHoverBg: '#4572ED',
          },
          Tooltip: {
            colorTextLightSolid: '#aaadb6',
          },
        },
        token: {
          fontFamily: 'Rubik',
        },
      }}
    >
      <AntdApp>
        <LoadingWrapper isLoading={isLoading}>
          <ConfigProvider value={config}>
            <Router>
              <Routes>
                <Route path="/" element={<Navigate to={RoutesPath.Tests} />} />
                {routes.map(({ component: Component, isPublic, path, props }) => (
                  <Route
                    key={path}
                    element={
                      <AuthGuard isPublic={isPublic}>
                        <Component {...props} />
                      </AuthGuard>
                    }
                    path={path}
                  />
                ))}

                <Route
                  path="*"
                  element={
                    <AuthGuard>
                      <NotFound />
                    </AuthGuard>
                  }
                />
              </Routes>
            </Router>
          </ConfigProvider>
        </LoadingWrapper>
      </AntdApp>
    </AntdConfigProvider>
  );
};
