import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { EmployeeResponsePrivate, EmployeeStatus } from '@tests/types';
import {
  Avatar,
  Button,
  Col,
  Input,
  message,
  Modal,
  notification,
  Row,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { GetProp, UploadFile } from 'antd/lib';
import classNames from 'classnames';
import { ChangeEventHandler, useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { ProjectsFilter, SelectRender } from '@/components';
import { ConfigContext } from '@/providers';
import { rolesEntitySelector } from '@/selectors';
import {
  useCreateUploadFileMutation,
  useGetFileByIdMutation,
  useUpdateEmployeeMutation,
  useUploadFileMutation,
} from '@/services';
import { getArrayBuffer, isSuccessResult, STATUS_OPTIONS } from '@/utils';

import styles from './styles.module.scss';

type Props = {
  employee: EmployeeResponsePrivate<string>;
  employeesRefetch: () => void;
  fetchRolesData: () => Promise<void>;
  hasMoreRoles: boolean;
  isRolesFetching: boolean;
  onClose: () => void;
};

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const EmployeeEditor: React.FC<Props> = ({
  employee,
  employeesRefetch,
  fetchRolesData,
  hasMoreRoles,
  isRolesFetching,
  onClose,
}) => {
  const [name, setName] = useState(employee?.name);
  const [status, setStatus] = useState(employee?.status);
  const [role, setRole] = useState({ label: employee?.role?.name, value: employee?.roleId });
  const [checkedProjects, setCheckedProjects] = useState(
    employee?.projects.map(({ id, name }) => ({ label: name, value: id })),
  );
  const [avatar, setAvatar] = useState(employee?.avatar);

  const [updateBlock] = useUpdateEmployeeMutation();

  const [uploadFile] = useUploadFileMutation();
  const [createUploadFile] = useCreateUploadFileMutation();
  const [getFileById] = useGetFileByIdMutation();

  const {
    upload: { maxSizeImage },
  } = useContext(ConfigContext);

  const roles = useSelector(rolesEntitySelector.selectAll) || [];
  const roleOptions = roles.map(({ id, name }) => ({ label: name, value: id }));

  const handleSetRole = (_value: number, option: any) => {
    setRole(option);
  };

  const handleSetStatus = (value: EmployeeStatus) => {
    setStatus(value);
  };

  const handleName: ChangeEventHandler<HTMLInputElement> = (event) => {
    setName(event.target.value);
  };

  const handleSaveEmployee = async () => {
    await updateBlock({
      avatarId: avatar?.id,
      id: employee.id,
      name,
      projectIds: checkedProjects?.map(({ value }) => value),
      roleId: role.value,
      status,
    });
    employeesRefetch();
    onClose();
  };

  const onUploadFile = useCallback(
    async (file: UploadFile<any>) => {
      try {
        const { size: contentLength, type: contentType } = file;
        const createUploadResult = await createUploadFile({
          contentLength,
          contentType,
        });

        if (!isSuccessResult(createUploadResult)) {
          throw createUploadResult.error;
        }

        const {
          data: { fileId, uploadUrl },
        } = createUploadResult;

        const data = await getArrayBuffer(file.originFileObj);

        const uploadResult = await uploadFile({
          contentType,
          data,
          url: uploadUrl,
        });

        if (!isSuccessResult(uploadResult)) {
          throw uploadResult.error;
        }

        const fileResponse = await getFileById(fileId);

        if (!isSuccessResult(fileResponse)) {
          throw fileResponse.error;
        }

        setAvatar(fileResponse?.data);
      } catch (e: any) {
        notification.error({
          description: JSON.stringify(e?.message || ''),
          message: `Ошибка загрузки ${e?.code || ''}`,
        });
      }
    },
    [createUploadFile, getFileById, uploadFile],
  );

  const beforeUpload = (file: FileType) => {
    const isLt2M = file.size / 1024 / 1024 < maxSizeImage;
    if (!isLt2M) {
      message.error('Image must smaller than 1MB!');
    }

    return isLt2M;
  };

  const handleDeleteAvatar = async () => {
    setAvatar(null);
  };

  return (
    <Modal
      open
      getContainer={() => document.getElementById('modal-container')}
      onCancel={onClose}
      mask={false}
      closable={false}
      footer={null}
      centered
      width={400}
      rootClassName={styles.editorWrapper}
    >
      <Col className={styles.editor}>
        <Row className={styles.editorTitle}>
          <Col>
            <Typography.Text className={styles.editorTitleText}>Редактирование</Typography.Text>
          </Col>
          <Col>
            <CloseOutlined onClick={onClose} />
          </Col>
        </Row>
        <Row className={styles.avatarWrapper}>
          <Col className={styles.avatar}>
            <Avatar size={122} src={avatar?.url} />
            <Row gutter={[8, 0]} className={styles.avatarEditBtns}>
              <Col>
                <Upload
                  name="avatar"
                  listType="picture"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={(info) => onUploadFile(info.file)}
                >
                  <Button icon={<EditOutlined />} />
                </Upload>
              </Col>
              <Col>
                <Button icon={<DeleteOutlined />} onClick={handleDeleteAvatar} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[0, 10]} className={styles.inputWrapper}>
          <Typography.Text>ФИО</Typography.Text>
          <Input value={name} onChange={handleName} />
        </Row>
        <Row justify="space-between" className={styles.idWrapper}>
          <Col>
            <Typography.Text type="secondary">ID:</Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{employee.id}</Typography.Text>
          </Col>
        </Row>
        <Row justify="center" align="middle" className={styles.filters}>
          <Row className={styles.filterRow}>
            <Typography.Text type="secondary">Статус</Typography.Text>
            <SelectRender
              value={status}
              options={STATUS_OPTIONS}
              onChange={handleSetStatus}
              isEditor
              classname="employeeEdit"
            />
          </Row>
          <Row className={styles.filterRow}>
            <Typography.Text type="secondary">Роль</Typography.Text>
            <SelectRender
              value={role}
              hasMore={hasMoreRoles}
              fetchData={fetchRolesData}
              loading={isRolesFetching}
              options={roleOptions}
              onChange={handleSetRole}
              isEditor
              classname="employeeEdit"
            />
          </Row>
          <Row className={classNames(styles.filterRow, styles.filterProjectsRow)}>
            <Typography.Text type="secondary">Проекты</Typography.Text>
            <ProjectsFilter
              checkedProjects={checkedProjects}
              setCheckedProjects={setCheckedProjects}
              isEditor
              classname="employeeEdit"
            />
          </Row>
        </Row>
        <Row gutter={[0, 0]} className={styles.editBtnWrapper} justify="space-between">
          <Col>
            <Button className={classNames(styles.editBtn, styles.editCloseBtn)} onClick={onClose}>
              Отменить
            </Button>
          </Col>
          <Col>
            <Button
              className={classNames(styles.editBtn, styles.editSaveBtn)}
              onClick={handleSaveEmployee}
              type="primary"
            >
              Сохранить
            </Button>
          </Col>
        </Row>
      </Col>
    </Modal>
  );
};
