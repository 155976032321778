import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Player } from '@lottiefiles/react-lottie-player';
import { TestResponsePrivate } from '@tests/types';
import { Col, Row, Space, Tabs, TabsProps } from 'antd';
import classNames from 'classnames';
import { inc } from 'ramda';
import React, { useCallback, useState } from 'react';

import { Close, Loader } from '@/assets';
import {
  useCreateResultMutation,
  useDeleteResultMutation,
  useGetResultsListQuery,
} from '@/services';
import { isCrossword, isPoll, isQuiz, isTest } from '@/utils';

import { Result } from './Result';
import styles from './styles.module.scss';

type Props = {
  test: TestResponsePrivate;
};

export const Results: React.FC<Props> = ({ test }) => {
  const { id, type } = test;

  const [activeTab, setActiveTab] = useState('index-0');

  const { data: { results } = {}, isLoading } = useGetResultsListQuery(
    { relations: ['media'], test: id },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    },
  );

  const [createResult] = useCreateResultMutation();
  const [deleteResult] = useDeleteResultMutation();

  const onTabClick = (key: string) => {
    setActiveTab(key);
  };

  const handleAddResult = useCallback(() => {
    createResult({ testId: id });
  }, [createResult, id]);

  const additionalTabButtons = {
    right: (
      <Space style={{ cursor: 'pointer' }} onClick={handleAddResult}>
        <Row
          align="middle"
          className={classNames(styles.additionalTab, styles.active, styles[type])}
        >
          <PlusOutlined />
        </Row>
        <Row
          align="middle"
          className={classNames(styles.additionalTab, styles.active, styles[type])}
        >
          <Col span={24}>Диапазон</Col>
        </Row>
      </Space>
    ),
  };

  if (!results) {
    if (isLoading) {
      return (
        <div className={styles.loaderWrapper}>
          <Player autoplay loop src={Loader} className={styles.loader} />
        </div>
      );
    }

    return <div>Нет результатов!</div>;
  }

  if (isCrossword(type)) {
    const result = results[0];

    return (
      <div className={styles.resultsWrapper}>
        <Result result={result} index={0} key={`index-${result.id}`} testType={type} />
      </div>
    );
  }

  const items: TabsProps['items'] = results?.map((result, index) => ({
    children: <Result result={result} index={index} key={`index-${result.id}`} testType={type} />,
    key: `index-${index}`,
    label: (
      <Row gutter={[5, 5]} wrap={false}>
        <Col
          className={classNames(
            styles.additionalTab,
            activeTab === `index-${index}` && styles.active,
            styles[type],
          )}
        >
          {`Диапазон №${inc(index)}`}
        </Col>
        {results.length > 2 && (
          <Close
            onClick={(e) => {
              e.stopPropagation();
              deleteResult(result.id);
            }}
            className={classNames(
              styles.close,
              activeTab === `index-${index}` && styles.active,
              styles[type],
            )}
          />
        )}
      </Row>
    ),
  }));

  return (
    <div className={styles.resultsWrapper}>
      <Tabs
        onTabClick={onTabClick}
        tabBarExtraContent={results.length < 10 && !isCrossword(type) && additionalTabButtons}
        className={classNames(
          styles.tabs,
          isTest(type) && styles.testTabs,
          isPoll(type) && styles.pollTabs,
          isQuiz(type) && styles.quizTabs,
        )}
        defaultActiveKey="index-0"
        items={items}
        moreIcon={<EllipsisOutlined className={styles.moreTabs} />}
        popupClassName={styles.popup}
      />
    </div>
  );
};
