import { createApi } from '@reduxjs/toolkit/query/react';
import { GetManyProjectsQueryPrivate, GetManyProjectsResponsePrivate } from '@tests/types';

import { ApiPath } from '@/constants';
import { createUrl } from '@/utils';

import { authGuardQuery } from './queries';

export const projectsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    getProjectsList: build.query<
      GetManyProjectsResponsePrivate,
      RelationsParams & Partial<GetManyProjectsQueryPrivate>
    >({
      providesTags: (result) => [
        ...(result?.results?.map((project) => ({
          id: project.id,
          type: 'Projects' as const,
        })) || []),
        { id: 'List', type: 'Projects' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Projects),
      }),
    }),
  }),
  reducerPath: 'projectsApi',
  tagTypes: ['Projects'],
});

export const { useGetProjectsListQuery } = projectsApi;
