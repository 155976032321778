import { Button, Col, Progress, Row, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, ifElse, T } from 'ramda';
import React, { useCallback, useMemo } from 'react';

import { AddImage } from '@/assets';
import { DropType } from '@/constants';

import styles from './styles.module.scss';

interface Props {
  isError: boolean;
  isProcessing: boolean;
  isUploading: boolean;
  passedPreviewText?: string;
  size?: string;
  type: DropType;
  uploadProgress: number;
  validErrorMessage?: string;
}

const getPreviewText = cond<[DropType], string>([
  [equals(DropType.All), always('Добавьте изображение')],
]);

const getErrorText = cond<[DropType], string>([
  [equals(DropType.Image), always('Ошибка загрузки изображения')],
]);

const getPreviewIcon = cond<[DropType], JSX.Element>([[T, always(<AddImage />)]]);

export const Preview: React.FC<Props> = ({
  isError,
  isProcessing,
  isUploading,
  passedPreviewText,
  size = 'big',
  type,
  uploadProgress,
  validErrorMessage,
}) => {
  const isSmall = ifElse(equals('small'), always(true), always(false));

  const PreviewIcon = useCallback(() => {
    if (isProcessing || isUploading) {
      return (
        <Progress
          percent={isUploading ? uploadProgress : 100}
          type="circle"
          width={isSmall(size) ? 24 : 50}
        />
      );
    }

    return getPreviewIcon(type);
  }, [isProcessing, isUploading, type, uploadProgress]);

  const previewText = useMemo(() => {
    if (isError) {
      return getErrorText(type);
    }
    if (isProcessing) {
      return 'Обработка';
    }
    if (isUploading) {
      return 'Загрузка';
    }
    if (passedPreviewText) {
      return passedPreviewText;
    }

    return getPreviewText(type);
  }, [isError, isProcessing, isUploading, passedPreviewText, type]);

  return (
    <Row
      className={classNames(
        styles.previewWrapper,
        isSmall(size) && styles.small,
        validErrorMessage && styles.error,
      )}
    >
      <Tooltip title={validErrorMessage}>
        <Col span={24}>
          <Row gutter={isSmall(size) ? [0, 0] : [15, 15]}>
            <Col span={24}>
              <div className={classNames(styles.previewIconWrapper, isSmall(size) && styles.small)}>
                <PreviewIcon />
              </div>
            </Col>
            <Col span={24}>
              <Typography.Text
                className={classNames(styles.previewLabel, isSmall(size) && styles.small)}
              >
                {previewText}
              </Typography.Text>
            </Col>
            {!isSmall(size) && (
              <Col span={24}>
                <Button className={styles.defaultButton}>Добавить файл</Button>
              </Col>
            )}
          </Row>
        </Col>
      </Tooltip>
      {!isSmall(size) && (
        <Col span={24}>
          <span className={styles.format}>допустимый формат </span>
          <span>jpeg, png, gif</span>
        </Col>
      )}
    </Row>
  );
};
